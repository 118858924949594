import { Box, useTheme } from '@chakra-ui/react'
import {
  HeroFeatured_Swnz_HeroFeaturedFragment,
  Swnz_Hero,
  Swnz_HeroFeatured,
} from 'content-service'
import { ReactElement } from 'react'
import {
  FinderHero,
  FinderHeroProps,
  HeroFeatured,
  HeroFeaturedProps,
  HeroSelect,
  OptimisedImage,
} from 'ui'
import {
  createCtaButton,
  createHeroFloatingCardProps,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG,
  useMediaQuery,
} from 'utils'
import {
  CourseFinderWidget,
  PathwaysFinderWidget,
  ProviderFinderWidget,
  ScholarshipFinderWidget,
  SchoolFinderWidget,
} from '../Search'

type HeroFeatured = HeroFeatured_Swnz_HeroFeaturedFragment
type Hero = Swnz_Hero | Swnz_HeroFeatured

type HeroFeaturedComponent = HeroFeaturedProps & {
  leadCaptureFormId?: string
}

type HeroProps = {
  hero: Hero
  displayType: string
  pageTheme?: string
  leadCaptureFormId?: string
}

const createHeroFeaturedComponentProps = (
  hero: HeroFeatured,
  leadCaptureFormId?: string,
  isFocusedOnFaces?: boolean
): HeroFeaturedComponent => ({
  heading: hero?.heading || '',
  subHeading: hero?.subHeading || '',
  introText: hero?.introText || '',
  primaryCta: createCtaButton(hero?.primaryCta),
  secondaryCta: createCtaButton(hero?.secondaryCta),
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FEATURED_HERO,
    objectPosition: '80%',
    loading: 'eager',
    blurOnLoad: false,
    onFocusBreakpoint: isFocusedOnFaces,
  }),
  heroFloatingCard: hero?.heroFloatingCard && createHeroFloatingCardProps(hero?.heroFloatingCard),
  leadCaptureFormId: leadCaptureFormId,
  textThemeColour: hero?.textThemeColour,
})

export enum FinderDisplayType {
  FinderCourse = 'finder-course',
  FinderOnline = 'finder-online',
  FinderPathways = 'finder-pathway',
  FinderPHD = 'finder-phd',
  FinderMasters = 'finder-masters',
  FinderProvider = 'finder-institution',
  FinderUniversity = 'finder-universities',
  FinderSchool = 'finder-school',
  FinderScholarship = 'finder-scholarships',
}

interface FinderWidgetMap {
  [displayType: string]: ReactElement
}

const createFinderHeroProps = (hero: Hero): Omit<FinderHeroProps, 'finderWidget'> => ({
  heading: hero?.heading || '',
  backgroundColour: 'backgroundColour' in hero ? hero?.backgroundColour ?? '' : '',
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%',
  }),
})

export const finderWidgetMap: FinderWidgetMap = Object.freeze({
  [FinderDisplayType.FinderCourse]: (
    <CourseFinderWidget
      initialSearchState={{
        menu: {
          'deliveryModes.name': 'Study in NZ',
          'countries.name': 'New Zealand',
        },
      }}
    />
  ),
  [FinderDisplayType.FinderOnline]: (
    <CourseFinderWidget initialSearchState={{ menu: { 'deliveryModes.name': 'Study online' } }} />
  ),
  [FinderDisplayType.FinderPHD]: (
    <CourseFinderWidget initialSearchState={{ menu: { 'levelOfStudy.name': 'Doctoral Degree' } }} />
  ),
  [FinderDisplayType.FinderMasters]: (
    <CourseFinderWidget initialSearchState={{ menu: { 'levelOfStudy.name': "Master's Degree" } }} />
  ),
  [FinderDisplayType.FinderProvider]: <ProviderFinderWidget />,
  [FinderDisplayType.FinderUniversity]: (
    <ProviderFinderWidget initialSearchState={{ menu: { 'subtypeList.name': 'University' } }} />
  ),
  [FinderDisplayType.FinderPathways]: (
    <PathwaysFinderWidget
      initialSearchState={{ menu: { 'deliveryModes.name': 'Global pathways' } }}
    />
  ),
  [FinderDisplayType.FinderSchool]: (
    <SchoolFinderWidget
      initialSearchState={{
        refinementList: {
          subtype: ['Primary School', 'Intermediate School', 'Secondary School'],
          // using countries was causing it to be set to 'market'
          'countries.name': ['New Zealand'],
        },
      }}
    />
  ),
  [FinderDisplayType.FinderScholarship]: <ScholarshipFinderWidget />,
})

function Hero({ hero, leadCaptureFormId, displayType, pageTheme }: HeroProps) {
  const theme = useTheme()
  const isFocusedOnFaces = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  if (hero?.__typename === 'SWNZ_HeroFeatured') {
    return (
      <HeroFeatured
        {...createHeroFeaturedComponentProps(hero, leadCaptureFormId, isFocusedOnFaces)}
      />
    )
  }

  if (hero?.__typename === 'SWNZ_Hero') {
    if (finderWidgetMap[displayType]) {
      return (
        <Box>
          <FinderHero
            {...createFinderHeroProps(hero)}
            finderWidget={finderWidgetMap[displayType]}
          />
        </Box>
      )
    }

    return (
      <HeroSelect
        pageTheme={pageTheme}
        displayType={displayType}
        hero={hero ?? undefined}
        leadCaptureFormId={leadCaptureFormId}
      />
    )
  }

  return null
}

export default Hero
