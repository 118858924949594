import { Box, Container } from '@chakra-ui/react'
import {
  Hero_Swnz_HeroFragment,
  Hero_Swnz_HeroFragmentDoc,
  HeroFeatured_Swnz_HeroFeaturedFragment,
  HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
  Seo_Swnz_SeoFragment,
  StandardPage_Swnz_PageStandardFragment,
} from 'content-service'
import dynamic from 'next/dynamic'
import { ReactElement, useRef } from 'react'
import Hero from 'src/components/Hero'
import { finderWidgetMap } from 'src/components/Hero/Hero'
import { theme } from 'theme'
import { ElementSelect, getHeroIntroWrapperBgColor, HeroIntroWrapper, PageSeo } from 'ui'
import {
  colourMap,
  createHeroImageProps,
  SectionCollectionId,
  useFragment,
  useMediaQuery,
  ZINDEX,
} from 'utils'

export interface StandardPageProps {
  content: StandardPage_Swnz_PageStandardFragment | null
  codeId: string | undefined | null
  pageSeo?: Seo_Swnz_SeoFragment
  pageTheme?: string
}

interface PageElements {
  elements: SectionCollectionId[]
  leadCaptureFormId?: string
  pageTheme?: string
}

const StoryModal = dynamic(() => import('ui/src/components/StoryModal/StoryModal'))

function validateSWNZHero(hero) {
  if (hero?.__typename === 'SWNZ_Hero') {
    return {
      ...hero,
      textThemeColour: undefined,
    }
  }

  if (hero?.__typename === 'SWNZ_HeroFeatured') {
    return {
      ...hero,
      backgroundColour: undefined,
      displayType: undefined,
    }
  }

  return undefined
}

function IntroElement({
  intro,
  pageTheme,
  leadCaptureFormId,
  textColor,
}: {
  intro: SectionCollectionId
  pageTheme?: string
  leadCaptureFormId?: string
  textColor?: string | null
}) {
  return intro?.sys.id && intro?.__typename ? (
    <ElementSelect
      id={intro.sys?.id}
      typename={intro.__typename}
      isIntroElement={true}
      pageTheme={pageTheme}
      leadCaptureFormId={leadCaptureFormId}
      textColor={textColor}
    />
  ) : null
}

function PageElements({ elements, leadCaptureFormId, pageTheme }: PageElements) {
  return (
    <>
      {elements.map((item, index) => {
        if (item?.sys.id && item?.__typename) {
          // prettier-ignore
          const { sys: { id }, __typename: typename } = item
          return (
            <ElementSelect
              id={id}
              typename={typename}
              key={index}
              isIntroElement={false}
              pageTheme={pageTheme}
              leadCaptureFormId={leadCaptureFormId}
            />
          )
        }
      })}
    </>
  )
}

function getLeadCaptureFormId(elements: SectionCollectionId[]) {
  const leadCaptureForm = elements.find((item) => item?.__typename === 'SWNZ_FormLeadCapture')
  return leadCaptureForm?.sys?.id
}

const StandardPage = ({
  content,
  pageSeo,
  codeId,
  pageTheme,
}: StandardPageProps): ReactElement | null => {
  const heroData = useFragment<Hero_Swnz_HeroFragment>({
    id: content?.hero?.sys?.id ?? '',
    typename: content?.hero?.__typename ?? '',
    fragment: Hero_Swnz_HeroFragmentDoc,
    fragmentName: 'hero_SWNZ_Hero',
  })
  const heroFeaturedData = useFragment<HeroFeatured_Swnz_HeroFeaturedFragment>({
    id: content?.hero?.sys?.id ?? '',
    typename: content?.hero?.__typename ?? '',
    fragment: HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
    fragmentName: 'heroFeatured_SWNZ_HeroFeatured',
  })
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)
  const mainRef = useRef<HTMLDivElement>(null)

  if (content == null) {
    return null
  }

  const unvalidatedHeroData =
    content?.hero?.sys?.id === heroData?.sys?.id ? heroData : heroFeaturedData
  const hero = validateSWNZHero(unvalidatedHeroData)

  const heroDisplayType = `${codeId}`.includes('finder') ? `${codeId}` : `${hero?.displayType}`

  const introElementBackgroundColor =
    content.intro && 'backgroundColour' in content.intro
      ? content.intro.backgroundColour ?? 'white'
      : undefined

  const isBrowser = typeof window !== 'undefined'

  const siblingElement = mainRef?.current?.children?.[1] as HTMLElement | null
  const siblingElementBackgroundColor =
    siblingElement && isBrowser
      ? window.getComputedStyle(siblingElement).backgroundColor
      : undefined

  const nextElementBackgroundColor = colourMap(
    introElementBackgroundColor || siblingElementBackgroundColor || 'white'
  )

  const heroBackgroundColor = getHeroIntroWrapperBgColor({
    heroType: hero?.__typename,
    displayType: heroDisplayType,
    backgroundColor: hero?.backgroundColour ?? '',
    textColor: hero?.textThemeColour,
    isDesktop: isDesktop,
    nextElementBackgroundColor,
  })

  const metaImgSrc = createHeroImageProps(hero?.mediaItem).src
  const elements = content.sectionsCollection?.items ?? []
  const leadCaptureFormId = getLeadCaptureFormId(elements)

  return (
    <Box as="main" ref={mainRef}>
      {pageSeo ? <PageSeo pageSeo={pageSeo} metaImgSrc={metaImgSrc} /> : null}
      <HeroIntroWrapper backgroundColor={heroBackgroundColor}>
        <Hero
          hero={hero}
          pageTheme={pageTheme}
          displayType={heroDisplayType}
          leadCaptureFormId={leadCaptureFormId}
        />
        {hero?.__typename === 'SWNZ_HeroFeatured' &&
        heroDisplayType &&
        finderWidgetMap[heroDisplayType] ? (
          <Container
            position="relative"
            zIndex={ZINDEX.HERO_FEATURED_CARD}
            marginTop={{ lg: '-40px' }}
            px={{ base: 0, lg: 16 }}
            backgroundColor={{
              base: nextElementBackgroundColor,
              lg: 'transparent',
            }}
          >
            <Box>{finderWidgetMap[heroDisplayType]}</Box>
          </Container>
        ) : (
          <></>
        )}
        <IntroElement
          intro={content.intro}
          pageTheme={pageTheme}
          leadCaptureFormId={leadCaptureFormId}
          textColor={hero?.textThemeColour}
        />
      </HeroIntroWrapper>
      <PageElements
        pageTheme={pageTheme}
        elements={elements}
        leadCaptureFormId={leadCaptureFormId}
      />

      <StoryModal key={content.sys.id} />
    </Box>
  )
}

export default StandardPage
